import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";

const initialState = {
    data: [],
};

export const StudentsAttendancesReport = createSlice({
    name: "StudentsAttendancesReport",
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        clearData: (state) => {
            state.data = initialState.data;
        },
        clearState: () => {
            return initialState;
        },
    },
});

export const { setData, clearData, clearState } =
    StudentsAttendancesReport.actions;

export default StudentsAttendancesReport.reducer;

// Actions
export const startGetAttendancesGeneral = ({
    cycles,
    coordination_ids,
    curriculum_ids,
    status,
    degree,
    letter,
    faults_percentage,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("cycles", `${cycles?.year}-${cycles?.sub_cycle}`);

        if (coordination_ids?.length) {
            params.set(
                "coordination_ids",
                coordination_ids.map((item) => item.value).join(",")
            );
        }

        params.set(
            "curriculum_ids",
            curriculum_ids.map((item) => item.value).join(",")
        );

        if (status?.length) {
            params.set("status", status.map((item) => item.value).join(","));
        }

        if (degree?.length) {
            params.set("degree", degree.map((item) => item.value).join(","));
        }

        if (letter?.length) {
            params.set("letter", letter.map((item) => item.value).join(","));
        }

        params.set("faults_percentage", faults_percentage);

        const resp = await Call(
            `students/attendances/general`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            if (resp.success?.count == 0) {
                Swal.fire({
                    title: "Sin información",
                    text: "No se encontró información de búsqueda con los filtros seleccionados.",
                    toast: true,
                    position: "bottom-end",
                    icon: "warning",
                    timer: 8000,
                });
            }
            dispatch(setData(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startExportAttendancesGeneral = ({
    cycles,
    coordination_ids,
    curriculum_ids,
    status,
    degree,
    letter,
    faults_percentage,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const cycles_data = `${cycles.year}-${cycles.sub_cycle}`;

        const params = new URLSearchParams();

        params.set("export", 1);

        params.set("cycles", `${cycles?.year}-${cycles?.sub_cycle}`);

        if (coordination_ids?.length) {
            params.set(
                "coordination_ids",
                coordination_ids.map((item) => item.value).join(",")
            );
        }

        params.set(
            "curriculum_ids",
            curriculum_ids.map((item) => item.value).join(",")
        );

        if (status?.length) {
            params.set("status", status.map((item) => item.value).join(","));
        }

        if (degree?.length) {
            params.set("degree", degree.map((item) => item.value).join(","));
        }

        if (letter?.length) {
            params.set("letter", letter.map((item) => item.value).join(","));
        }

        params.set("faults_percentage", faults_percentage);

        const response = await CallWithFormDataFile(
            `students/attendances/general`,
            "GET",
            params.toString()
        );
        downloadBlob(response, `Reporte de asistencias alumnos`);

        dispatch(stopUILoading());
    };
};
